import moment from "moment";
import NominatifPembiayaanByKontrakListPdf from "@/components/page/nominatif-pembiayaan-by-kontrak-list-pdf";
import vuePDF from "vue-pdf";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default {
  name: "NominatifLoanAccount",
  components: {
    NominatifPembiayaanByKontrakListPdf,
  },
  data() {
    return {
      pdf: {
        src: null,
        pdfToDownload: null,
        numPages: 0,
      },
      property: {
        listElement: {
          nominatif: {
            downloading: false,
            message: "",
          },
        },
        animation: {
          isDownloadingFile: false,
          NominatifPembiayaan: {
            isLoading: false,
          },
        },
        modal: {
          showModalPreviewPDFNominatif: false,
        },
      },
      dataForm: {
        userName: "",
        userCode: "",
        officeName: "",
        cabang: "",
        kodeProduk: "",
        exportContent: "",
        exportToExcel: {
          data: [],
          header: [],
        },
      },
      options: {
        cabang: [],
        eksport: [],
        kodeProduk: [],
      },
      table: {
        data: {
          nominatif: [],
        },
      },
    };
  },
  methods: {
    async onDownloadFileUs(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        officeCode: this.dataForm.cabang,
        loanCode: this.dataForm.kodeProduk,
        isComaDelimiter: true,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/nominatif-loan-account/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Laporan_Nominatif_Pembiayaan_Per_Kontrak.${
          fileType === "xls" ? "csv" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response.data.message,
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    async onDownloadFileIdn(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        officeCode: this.dataForm.cabang,
        loanCode: this.dataForm.kodeProduk,
        isComaDelimiter: true,
      };
      this.property.animation.isDownloadingFile = false;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/nominatif-loan-account/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Laporan_Nominatif_Pembiayaan_Per_Kontrak.${
          fileType === "xls" ? "csv" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response.data.message,
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },
    // helperGetUSerDataFromSession() {
    //   const getUserPayloadFromSession = JSON.parse(
    //     this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
    //   );
    //   this.dataForm.userName = getUserPayloadFromSession.userName;
    //   this.dataForm.userCode = getUserPayloadFromSession.userCode;
    //   this.dataForm.officeName = getUserPayloadFromSession.officeName;
    // },
    // getDateFormat(valDate) {
    //   var date = new Date(valDate);

    //   var dd = date.getDate();
    //   var mm = date.getMonth() + 1;
    //   var y = date.getFullYear();
    //   var time = date.toLocaleTimeString("en-GB");

    //   dd = dd.toString().length == 1 ? "0" + dd : dd;
    //   mm = mm.toString().length == 1 ? "0" + mm : mm;

    //   var formattedDate = dd + "-" + mm + "-" + y + " " + time;
    //   return formattedDate;
    // },
    // async getReportNominatifByKontrak() {
    //   if (window.navigator.onLine === false) {
    //     this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
    //     return;
    //   } else {
    //     this.$buefy.dialog.confirm({
    //       message: "Proses Data ?",
    //       type: "is-success",
    //       onConfirm: async () => {
    //         this.pdf.src = null;
    //         this.pdf.numPages = 0;
    //         this.pdf.pdfToDownload = null;
    //         this.property.animation.NominatifPembiayaan.isLoading = true;
    //         try {
    //           const payload = {
    //             loanCode: this.dataForm.kodeProduk,
    //             officeCode: this.dataForm.cabang,
    //             fileType: "pdf",
    //           };
    //           const response = await this.$store.dispatch({
    //             type: "GET_SAVE_RESPONSE_DATA",
    //             endPoint: "report",
    //             reqUrl: "report/nominatif-loan-account/files",
    //             payload: payload,
    //           });
    //           console.log("Success", response);
    //           console.log(response.data);
    //           const blob = new Blob([response.data]);
    //           console.log(blob);
    //           const objectUrl = URL.createObjectURL(blob);
    //           var load = vuePDF.createLoadingTask(objectUrl);
    //           this.pdf.pdfToDownload = objectUrl;
    //           this.pdf.src = load;
    //           this.pdf.src.promise.then((pdf) => {
    //             this.pdf.numPages = pdf.numPages;
    //           });
    //           this.property.modal.showModalPreviewPDFNominatif = true;
    //         } catch (error) {
    //           this.pdf.src = null;
    //           setTimeout(() => {
    //             console.log(error.response);
    //             this.$buefy.dialog.alert({
    //               title: "Gagal !",
    //               message: error.response
    //                 ? `${error.response.statusText}`
    //                 : "Terjadi Kesalahan",
    //               confirmText: "Ok",
    //               type: "is-danger",
    //             });
    //           }, 1500);
    //         } finally {
    //           setTimeout(() => {
    //             this.property.animation.NominatifPembiayaan.isLoading = false;
    //           }, 1000);
    //         }
    //       },
    //     });
    //   }
    // },
    // async directGetReportNominatifByKontrak() {
    //   if (window.navigator.onLine === false) {
    //     this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
    //     return;
    //   }
    //   this.property.animation.NominatifPembiayaan.isLoading = true;
    //   this.property.listElement.nominatif.downloading = true;
    //   this.table.data.nominatif = [];
    //   try {
    //     const resp = await this.$store.dispatch({
    //       type: "GET_LIST_DATA",
    //       endPoint: "report",
    //       reqUrl: "report/nominatif-loan-contract",
    //       payload: {
    //         officeCode: this.dataForm.cabang,
    //         kodeProduk: this.dataForm.kodeProduk,
    //       },
    //     });
    //     if (resp.data.code === "SUCCESS") {
    //       setTimeout(() => {
    //         this.table.data.nominatif = resp.data.data;
    //       }, 1000);
    //     } else {
    //       this.property.listElement.nominatif.message = resp.data.message;
    //     }
    //   } catch (error) {
    //     this.table.data.nominatif = [];
    //     this.property.listElement.nominatif.message = error.response
    //       ? `Gagal Mengunduh Data : ${error.response.statusText}`
    //       : "Terjadi Kesalahan";
    //   } finally {
    //     setTimeout(() => {
    //       this.property.animation.NominatifPembiayaan.isLoading = false;
    //       this.property.listElement.nominatif.downloading = false;
    //     }, 1000);
    //   }
    // },
    // appendDefaultOptionSelect() {
    //   const stringConditionDefaultOption = { value: "", text: "-- Pilih --" };
    //   this.options.cabang = [stringConditionDefaultOption];

    //   this.options.kodeProduk = [stringConditionDefaultOption];
    //   this.options.eksport.push(
    //     { value: "", text: "-- Pilih --" },
    //     { value: "EXCEL", text: "Excel" }
    //   );
    // },
    helperGetOfficeDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            officeName: "",
            page: 0,
          },
          url: "office",
        })

        .then((response) => {
          Promise.all(
            (this.options.cabang = [
              { text: "--- Semua Cabang ---", value: "" },
            ]),
            response.data.data.content.map((index) => {
              const value = index.officeCode;
              const text = index.officeName;

              this.options.cabang.push({ text, value });
            })
          ).then(() => {
            // this.getIdentityFromSession();
          });
        });
    },
    helperGetKodeProdukDataForReference() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_LOAN", {
          params: {
            loanName: "",
            page: 0,
            loanCode: "",
          },
          url: "loan/simple-list",
        })
        .then((response) => {
          this.options.kodeProduk = [
            { text: "--- Pilih Produk ---", value: "" },
          ];
          response.data.data.content.map((index) => {
            const text = `${index.loanName} - ${index.loanCode}`;
            const value = index.loanCode;
            this.options.kodeProduk.push({ text, value });
          });
        });
    },

    // getIdentityFromSession() {
    //   setTimeout(() => {
    //     var loanCodeSessions = sessionStorage.getItem(
    //       "LOAN_CODE_NOMINATIF_PEMBIAYAAN_BY_AKAD"
    //     );
    //     var officeCodeSessions = sessionStorage.getItem(
    //       "OFFICE_CODE_NOMINATIF_PEMBIAYAAN_BY_AKAD"
    //     );
    //     if (loanCodeSessions || officeCodeSessions) {
    //       this.dataForm.kodeProduk = `${loanCodeSessions}`;
    //       this.dataForm.cabang = `${officeCodeSessions}`;
    //       this.directGetReportNominatifByKontrak();
    //     }
    //   }, 2000);
    // },
  },
  mounted() {
    this.helperGetOfficeDataForReference();
    // this.appendDefaultOptionSelect();
    this.helperGetKodeProdukDataForReference();
    // this.helperGetUSerDataFromSession();
  },
  // destroyed() {
  //   sessionStorage.removeItem("LOAN_CODE_NOMINATIF_PEMBIAYAAN_BY_AKAD");
  //   sessionStorage.removeItem("OFFICE_CODE_NOMINATIF_PEMBIAYAAN_BY_AKAD");
  // },
};
